import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/components/render-from-template-context.js");
